<template>
  <div class="offers">
    <h2 class="offers__title title-page">Offers</h2>
    <div class="offers__main">
      <VCollapse class="mb-4">
        <template v-slot:header>
          Filters
        </template>
        <template v-slot:body>
          <div class="offers__row">
            <div class="offers__col">
              <div class="offers__filter-name">Search Name</div>
              <VSearch
                :initialValue="filter.name"
                placeholder="Search"
                class="xs"
                @input="searchByOffers"
                @clear="clearFilter('name')"
              />
            </div>
            <div class="offers__col">
              <div class="offers__filter-name">All Countries</div>
              <VMultiSelect
                placeholder="All Countries"
                :options="countries"
                :initialValue="filter.countries"
                @input="filterBySelect($event, 'countries')"
                @clear="clearFilter('countries')"
                ref="countries"
              />
            </div>
            <div class="offers__col">
              <div class="offers__filter-name">Categories</div>
              <VMultiSelect
                placeholder="Categories"
                :options="_categoriesForSelect"
                :initialValue="filter.categories"
                @input="filterBySelect($event,'categories')"
                @clear="clearFilter('categories')"
                ref="categories"
              />
            </div>
            <div class="offers__col">
              <v-button class="offers__btn" type="blue" @click="clearFilters">Clear filters</v-button>
            </div>
          </div>
        </template>
      </VCollapse>

      <VCollapse :showLine="true" class="mb-4">
        <template v-slot:header>
          Performance
        </template>
        <template v-slot:body>
          <div class="offers__performance">
            <div class="offers__performance-filter">
              <v-button
                type="orange"
                class="btn-xl"
                @click="$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', true)"
              >
                Columns
              </v-button>
              <div class="offers__col">
                <div class="offers__filter-name">Status</div>
                <VSelect
                  :options="_offerAffiliateStatus"
                  :value="filter.status"
                  size="sm"
                  get-type="object"
                  @input="filterBySelect($event.id,'status')"
                />
              </div>
              <v-button
                type="small-blue"
                icon="generate"
                @click="$router.push('/offers/tracking')"
              >
                Generate Traffic Links
              </v-button>
            </div>
            <keep-alive>
              <VMask v-if="$store.state.modalTableColumns.toggleTableColumns">
                <div class="offers__block-wrap">
                  <TableColumns
                    title="Table columns"
                    :default-columns="defaultColumns"
                    :default-selected-columns="_headCols"
                    :selected-columns-ids="_getOffersSettings.selectedColumns"
                    searchBy="selectedColumns"
                    @save="setSelectedColumns"
                  />
                </div>
              </VMask>
            </keep-alive>
          </div>
        </template>
      </VCollapse>

      <VLoader v-if="_fetchingOffersStatus === requestStatus.PENDING" />
      <VTable
        v-else-if="_fetchingOffersStatus === requestStatus.SUCCESS"
        :headCols="_headCols"
        :bodyRows="_offers"
        :actionsIsActive="true"
        :actionsList="actionsList"
        @onSortByCol="onSortByCol"
        @onAction="onAction"
      >
        <template #col-2="{ row }">
          <router-link :to="{ name: 'OfferDetails',  params: {id: row.network_offer_id} }">
            {{ row.name }}
          </router-link>
        </template>
        <template #col-5="{ row }">
          {{ row.relationship.ruleset.platforms[0] && row.relationship.ruleset.platforms[0].label || 'N/A' }}
        </template>
        <template #col-6="{ row }">
          {{ row.relationship.ruleset.countries[0] && row.relationship.ruleset.countries[0].label || 'N/A' }}
        </template>
        <template #col-13="{ row }">
          {{ offerAffiliateStatus[row.relationship.offer_affiliate_status] }}
        </template>
      </VTable>
    </div>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import VMultiSelect from '@/js/components/common/VMultiSelect';
import VSelect from "@/js/components/common/VSelect";
import VSearch from "@/js/components/common/VSearch";
import VMask from "@/js/components/common/VMask";
import TableColumns from "@/js/components/pages/offers/popup/TableColumns";
import VTable from "@/js/components/common/VTable";
import { requestStatus, offerAffiliateStatus } from '@/js/utils/constants';
import { defaultColumnsForOffers } from '@/js/utils/table/defaultColumns';
import meta from '@/js/utils/offers/meta.json';
import countries from "@/js/utils/offers/country.json";
import platform from "@/js/utils/offers/platform.json";

export default {
  name: "Offers",
  components: {
    VMultiSelect,
    VTable,
    TableColumns,
    VMask,
    VSearch,
    VCollapse,
    VSelect
  },
  data() {
    return {
      requestStatus,
      offerAffiliateStatus,
      defaultColumns: defaultColumnsForOffers,
      bodyRows: [],
      filterDefault: {
        name: '',
        platform: {},
        categories: [],
        payoutType: {},
        countries: [],
        status: 'all',
        order: '',
        sort: ''
      },
      filter: {
        name: '',
        platform: {},
        categories: [],
        payoutType: {},
        countries: [],
        status: 'all',
        order: '',
        sort: ''
      },
      countries: [],
      platforms: [],
      payoutTypes: [
        {
          id: 0,
          label: 'CPA'
        },
        {
          id: 1,
          label: 'CPA/CPS'
        },
        {
          id: 2,
          label: 'CPC'
        },
        {
          id: 3,
          label: 'CPS'
        },
        {
          id: 4,
          label: 'PRV'
        },
      ],
      actionsList: [
        {
          id: 0,
          label: 'Request Approval',
          slug: 'require_approval'
        },
        {
          id: 1,
          label: 'Offer Details',
          slug: 'offer_details'
        }
      ],
      timeoutSearchByOffers: null,
    };
  },
  computed: {
    _getOffersSettings() {
      return this.$store.getters['appSessionSettings/getOffersSettings'];
    },
    _headCols() {
      return this.defaultColumns
        .filter(column => this._getOffersSettings.selectedColumns.includes(column.id))
        .sort((a, b) => a.id - b.id);
    },
    _fetchingOffersStatus() {
      return this.$store.state.offers.fetchingOffersStatus;
    },
    _offerAffiliateStatus() {
      return Object.entries(this.offerAffiliateStatus).map((status) => ({
        id: status[0],
        label: status[1],
      }))
    },
    _offers() {
      return this.$store.state.offers.offers || [];
    },
    _categoriesForSelect() {
      return this.$store.state.offers.categories || [];
    },
  },
  mounted() {
    this.getOffers();
    this.getCategoryList();
    this.getCountriesList();
    this.getPlatformList();
  },
  methods: {
    searchByOffers(value) {
      this.filter.name = value;

      clearTimeout(this.timeoutSearchByOffers);

      this.timeoutSearchByOffers = setTimeout(() => {
        this.getOffers();
      }, 1000);
    },
    onAction(actionSlug, offer) {
      if (actionSlug === 'require_approval') {
        this.getApplicationQuestionnaire(offer.network_offer_id);
        this.getOffers();
      }
      if (actionSlug === 'offer_details') {
        this.$router.push(`/offers/${offer.network_offer_id}`);
      }
    },
    async getApplicationQuestionnaire(offerId) {
      await this.$store.dispatch('offers/getApplicationQuestionnaire', offerId);
    },
    async getOffers() {
      const params = {};
      const formData = {};

      const { countries, categories, payoutType, platform, status, name, order, sort } = this.filter;

      //formData
      if (Object.keys(countries).length) formData.country_ids = countries.map((country) => country.id);
      if (Object.keys(categories).length) formData.network_category_ids = categories.map((category) => category.id);
      if (Object.keys(payoutType).length) formData.payout_types = [payoutType?.label];
      if (Object.keys(platform).length) formData.platform_ids = [platform?.id];

      if (status) formData.affiliate_status = status;
      if (name) formData.name = name;

      //params
      params.page = 1;
      params.page_size = 9999;
      params.order_field = sort;
      params.order_direction = order;

      try {
        await this.$store.dispatch('offers/getOffers', {
          formData,
          params: {
            ...params,
          }
        });
      } catch (err) {
        console.log('=> ERR', err);
      }
    },

    async getCategoryList() {
      await this.$store.dispatch('offers/getCategoryList');
    },

    getCountriesList() {
      this.countries = countries.map((country) => ({
        id: country.country_id,
        label: meta.country[country.label_translate_key]
      }));
    },

    getPlatformList() {
      this.platforms = platform.map((platform) => ({
        id: platform.platform_id,
        label: meta.platform[platform.label_translate_key]
      }));
    },

    filterBySelect(option, slug) {
      this.filter[slug] = option;
      this.getOffers();
    },

    onSortByCol(order) {
      this._headCols.forEach(item => item.orderBy = 'def');
      const current = this._headCols.find(item => item.id === order.id);
      this.filter.order = order.orderBy;
      this.$set(current, 'orderBy', order.orderBy);
      this.filter.sort = order.sort;
      this.getOffers();
    },

    clearFilters() {
      // eslint-disable-next-line no-unused-vars
      const { status, ...filterData } = this.filterDefault;
      this.filter = { ...this.filter, ...filterData };
      this.getOffers();
    },

    clearFilter(slug) {
      this.filter[slug] = this.filterDefault[slug];
      this.getOffers();
    },
    setSelectedColumns(selectedColumns) {
      this.$store.commit('appSessionSettings/SET_SELECTED_COLUMNS', {
        pageKey: 'offers',
        selectedColumns,
      });
      this.$store.dispatch('appSessionSettings/updateSettings');
    },
  },
  destroyed() {
    this.$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', false);
  }
};
</script>

<style lang="scss" scoped>
.offers {
  &__main {
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: var(--main-box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(3, 260px) 100px;
    grid-column-gap: 24px;
    align-items: flex-end;

    @include media("max", "xl") {
      grid-template-columns: repeat(3, 200px) 100px;
      grid-column-gap: 16px;
    }
  }

  &__filter-name {
    margin-bottom: 8px;
  }

  &__performance-filter {
    display: grid;
    grid-template-columns: max-content 240px max-content;
    justify-content: flex-start;
    align-items: flex-end;
    grid-column-gap: 24px;
  }

  .row-item {
    max-width: 200px;
    height: 20px;
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &__block-wrap {
    max-width: 480px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .col-actions {
    position: relative;
  }
}

</style>