<template>
  <div class="v-multi-select" ref="multiSelect">
    <div v-if="label" class="mb-1">
      {{ label }}
    </div>
    <div
        class="v-multi-select-container"
        :class="{'form-input-error': validation}"
        v-click-outside="onBlurField"
    >
      <div class="v-multi-select__select">
        <input
            type="text"
            class="v-multi-select__input"
            :value="_initialValue"
            :placeholder="placeholder"
            @input="valueSearch = $event.target.value"
            @focus="dropdownIsVisible = true"
        />
        <icon-arrow-select class="s-arrow" :class="{'s-arrow-up': dropdownIsVisible}" />
        <div class="v-multi-select__btn-clear" @click.prevent.stop="onClearField">
          <icon-close color="#DDE4EB" />
        </div>
      </div>
    </div>
    <ul
        v-if="dropdownIsVisible"
        class="v-multi-select__select-options"
    >
      <div
        v-if="!_filteredOptions.length"
        class="v-multi-select__option v-multi-select__option--empty"
      >
        <VListIsEmpty />
      </div>
      <li
          v-for="option in _filteredOptions"
          :key="option.id"
          class="v-multi-select__option"
          :class="{'active': valueIsActive(option.id)}"
          @click="selectOption(option)"
      >
        <icon-check
            v-if="valueIsActive(option.id)"
            class="v-multi-select__option--icon"
        />
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import VListIsEmpty from '@/js/components/common/VListIsEmpty';
import IconArrowSelect from "@/js/components/icons/IconArrowSelect";
import IconCheck from '@/js/components/icons/IconCheck';
import IconClose from "@/js/components/icons/IconClose";

export default {
  name: "VMultiSelect",
  components: {
    VListIsEmpty,
    IconCheck,
    IconClose,
    IconArrowSelect
  },
  props: {
    label: String,
    options: Array, // receive array of - { id: Number, label: String }
    initialValue: Array,
    placeholder: String,
    validation: Boolean,
  },
  data() {
    return {
      valueSearch: '',
      dropdownIsVisible: false,
    };
  },
  computed: {
    _initialValue() {
      if (this.dropdownIsVisible) return this.valueSearch;
      return this.initialValue.map((value) => value.label).join(', ');
    },
    _filteredOptions() {
      return this.options.filter((option) => {
        return option.label.toLowerCase().includes(this.valueSearch.toLowerCase());
      });
    }
  },
  methods: {
    selectOption(option) {
      let valuesSelected = [];
      const initialValue = this.initialValue;
      const optionIdx = initialValue.findIndex((item) => item.id === option.id);

      if (optionIdx !== -1) {
        valuesSelected = initialValue.filter((item) => item.id !== initialValue[optionIdx].id);
      } else {
        valuesSelected = [...initialValue, option];
      }

      this.$emit('input', valuesSelected);
    },
    valueIsActive(optionId) {
      return this.initialValue.some(item => item.id === optionId);
    },
    onClearField() {
      if (!this.initialValue.length && !this.valueSearch.length) return;
      this.valueSearch = '';
      this.dropdownIsVisible = false;
      this.$emit('clear');
    },
    onBlurField(e) {
      if (this.$refs.multiSelect.contains(e.target)) return;
      this.valueSearch = '';
      this.dropdownIsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-multi-select {
  &__select {
    height: 40px;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    outline: none;
    width: 100%;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
  }

  &__select--name {
    cursor: pointer;
    outline: none;
    border: none;
    width: 100%;
  }

  .s-arrow {
    transform: rotate(0deg);
  }

  .s-arrow-up {
    transform: rotate(180deg);
  }

  &__select-options {
    width: 100%;
    max-width: 260px;
    max-height: 160px;
    position: absolute;
    margin: 0;
    padding: 0;
    background: #FFFFFF;
    border-radius: 8px 8px 10px 10px;
    box-shadow: 0 2px 6px 2px rgba(0, 42, 81, 0.1);
    overflow: auto;
    z-index: 10;
  }

  &__option {
    display: flex;
    align-items: center;
    height: 40px;
    padding-right: 8px;
    padding-left: 36px;
    cursor: pointer;

    &--icon {
      margin-right: 4px;
    }

    &.active {
      padding-left: 8px;
      background-color: var(--blue-light);
    }

    &:hover {
      background-color: var(--blue-light);
    }

    &--empty {
      justify-content: center;
      padding: 0 8px;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }

  &__btn-clear {
    border-left: 2px solid var(--grey-light-1);
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 8px;
  }

  .form-input-error {
    border: 1px solid var(--red-main);
    border-radius: 8px;
  }
}

</style>
